
import React, { useEffect, useState } from "react";
import "./Carousel.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {images} from "./Helpers/CarouselData"; 
import { InfinitySpin } from 'react-loader-spinner';


<InfinitySpin
height="80"
width="80"
radius="9"
color="orange"
ariaLabel="loading"
wrapperStyle
wrapperClass
/> 


function Carousel() {
  
const [currImg, setCurrImg] = useState(0);

//const data  = ["1", "2", "3"]

const carouselInfiniteScroll = () => {

  if (currImg === images.length-1){
    return setCurrImg(0)

  }
  return setCurrImg(currImg+1)
}
useEffect(() => {

  const interval = setInterval(() => {carouselInfiniteScroll()}, 10000)
  return () => clearInterval(interval)})


  return (
    <div className="Overlay">
    <div className="Carousel">

<div className="carouselInner" style={{backgroundImage: `url(${images[currImg].img})`
}}>


 <div className="left" onClick={()=> {
    
    currImg > 0 && setCurrImg(currImg - 1); } }>

<ArrowBackIosIcon style={{fontSize: 30}}  />

 </div>
 <div className="center">

<h1>{images[currImg].title} </h1>
<p>{images[currImg].subtitle} </p>

<a href={images[currImg].url} class="btn btn-custom btn-lg page-scroll">{images[currImg].btn}</a>
 </div>
 <div className="right" onClick={()=>{
    
   currImg < images.length - 1 && setCurrImg(currImg + 1); } }> <ArrowForwardIosIcon style={{fontSize: 30}}  /></div>

  </div>

 
    </div>
 
    </div>
  );
}

export default Carousel;
