export const Navigation = (props) => {
 
  return (

    
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
          <img src='img/logo.png' alt='Quantum Designers' width='280px' Style='position:absolute; top:15;' />
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'>
          <ul className='nav navbar-nav navbar-right'>
            <div className='row'>
            <li>
              <a data-toggle='collapse' data-target='.navbar-collapse.in' href='http://quantumdesigners.com/site' className='page-scroll'>
                Blog
              </a>
            </li>
            <li>
              <a data-toggle='collapse' data-target='.navbar-collapse.in' href='#about' className='page-scroll'>
                About
              </a>
            </li>

            <li>
              <a data-toggle='collapse' data-target='.navbar-collapse.in' href='#services' className='page-scroll'>
                Services
              </a>
            </li>
            <li>
              <a data-toggle='collapse' data-target='.navbar-collapse.in' href='#contact' className='page-scroll'>
                Contact
              </a>
          
            </li>
            <li>
              <a data-toggle='collapse' data-target='.navbar-collapse.in' href='#portfolio' className='page-scroll'>
               OUR WORK
              </a>
            </li>
            
            <li>
              <a data-toggle='collapse' data-target='.navbar-collapse.in' href='https://quantumdesigners.com/WHMCS/clientarea.php' target='_blank' className='page-scroll'>
                CLIENT LOGIN 
              </a>
            </li>
            <li>
              <a data-target='.navbar-collapse.in' href='https://quantumdesigners.com/cpanel' target='_blank' rel='noreferrer' className='page-scroll'>
                cPanel LOGIN
              </a>
            </li>
            <li>
              <a data-toggle='collapse' data-target='.navbar-collapse.in' href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li>
            <li>
              <a data-toggle='collapse' data-target='.navbar-collapse.in' href='#contentwriting' className='page-scroll'>
              Article Writing
              </a>
            </li>
         
			  </div>
          </ul>
        </div>
     
      </div>
    </nav>
  )
}

export default Navigation;
