import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Work</h2>
          <p>
          Our projects span a wide variety of web sites, unique design, theme based, Word Press development, E-commerce or just a single landing page.
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
       <a href={d.url} target='_blank' rel='noreferrer'><Image title={d.title} smallImage={d.smallImage}  /></a>
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    </div>
  )
}
