export const Wp = (props) => (

    
  <div align="center">
       <a
        href="https://api.whatsapp.com/send?phone=17039067076"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp fa-2x whatsapp-icon"> </i>
        <p Style="font-size:12px;">
            Need Help?<br/>
            Chat with Us</p>
      </a>

    
  </div>

);



