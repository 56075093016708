import useCollapse from 'react-collapsed';
function Collapsible(props)  {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
return ( 
    <div id='features' className='text-center'> 
     <div className="container">    
        <div className='col-md-10 col-md-offset-1 section-title'>
         { /* <h2>Features</h2>  */}
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-4 col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <div className="collapsible">
<div className="header" {...getToggleProps()}>
    
   <h3> {isExpanded ? '-' : '+'} </h3>
   </div>
<div {...getCollapseProps()}> 
                
                  <div className="content">
                  <p>{d.text}</p>
</div>
                </div> 
                </div>
                </div> 
                 ))
            : 'Loading...'}
        </div>
      </div>
      </div>
  )
}

export default Collapsible